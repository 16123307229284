import { useState, useContext } from 'react';
import {
  PasswordInput,
  Paper,
  Title,
  Container,
  Button,
  Alert,
  Text,
} from '@mantine/core';
import { useForm, isNotEmpty } from '@mantine/form';
import { IconAlertCircle } from '@tabler/icons-react';
import { useAppService } from '../services/AppService';
import './Login.css';
import { RootContext } from '../contexts/RootContext';
import { IconLogin } from '@tabler/icons-react';
import { useLocation } from 'react-router-dom';

enum FormStatus {
  READY,
  SUBMITTING,
  ERROR,
}

interface FormValues {
  password: string;
  passwordConfirm: string;
}

export function PasswordChallenge() {
  const context = useContext(RootContext);
  const appService = useAppService();
  const {state} = useLocation();
  const [formStatus, setFormStatus] = useState<FormStatus>(FormStatus.READY);
  const form = useForm<FormValues>({
    validateInputOnBlur: true,
    initialValues: {
      password: '',
      passwordConfirm: '',
    },
    validate: {
      password: isNotEmpty('Password is required'),
      passwordConfirm: (value, values) => value !== values.password ? 'Passwords do not match' : null,
    },
  });

  async function onSubmit(values: any) {
    setFormStatus(FormStatus.SUBMITTING);
    try {
      let response = await appService.respondToChallenge(state.username, values.password);
      context?.login(response.user, response.idToken, response.accessToken, response.refreshToken !== undefined ? response.refreshToken : null);
    } catch (e: any) {
      console.error(e);
      setFormStatus(FormStatus.ERROR);
    }
  };
  //sx={(theme) => ({ fontFamily: `Greycliff CF, ${theme.fontFamily}`, fontWeight: 900 })}
  return (
    <Container size={620} my={40}>
      <Title
        ta="center"
      >
        Please Change Your Passwrd
      </Title>
      <Text c="dimmed" size="sm" ta="center" mt={5}>
        Your password must be a minimum of 8 characters and contain at least 1 number, 1 uppercase letter, 1 lowercase letter, and one special character.
      </Text>
      <Paper withBorder shadow="md" p={30} mt={30} radius="md">
        {formStatus === FormStatus.ERROR && (
          <Alert icon={<IconAlertCircle size="1rem" />} variant="outline" title="Sign in failed" color="red" mb="1.5rem">
            Your password could not be updated. Please check that it conforms to the rules listed above or try again later.
          </Alert>
        )}
        <form onSubmit={form.onSubmit((values) => onSubmit(values))}>
        <PasswordInput
            withAsterisk
            label="Password"
            placeholder="Enter your password"
            mt="md"
            {...form.getInputProps('password')}
          />
          <PasswordInput
            withAsterisk
            label="Confirm Password"
            placeholder="Confirm your password"
            mt="md"
            {...form.getInputProps('passwordConfirm')}
          />
          <Button type="submit" leftSection={<IconLogin size="1rem" />} disabled={!form.isValid()} loading={formStatus === FormStatus.SUBMITTING} fullWidth mt="xl">
            {formStatus === FormStatus.SUBMITTING ? 'Submitting...' : 'Submit'}
          </Button>
        </form>
      </Paper>
    </Container>
  );
}