const DIMENSION_REGEX = /^\d+[ ]?x[ ]?\d+$/;
const POSITION_REGEX = /^[\d\.]+[ ]?,[ ]?[\d\.]+$/;
const COORDS_REGEX = /^\-?[\d\.]+[ ]?,[ ]?\-?[\d\.]+$/;
const HEX_REGEX = /^#[A-Fa-f0-9]{6,6}$/
const NUMERIC_REGEX = /^[\d\.]+$/

export const isStringEmpty = (s: string): boolean => {
  return (s === null || s === undefined || s === '');
}

export const isArrayEmpty = (a: []): boolean => {
  return (a === null || a === undefined || a.length <= 0);
}

export const isValidDimension = (s: string): boolean => {
  return s !== null && s !== undefined && s.match(DIMENSION_REGEX) !== null;
}

export const isValidPosition = (s: string): boolean => {
  return s !== null && s !== undefined && s.match(POSITION_REGEX) !== null;
}

export const isValidCoords = (s: string): boolean => {
  return s !== null && s !== undefined && s.match(COORDS_REGEX) !== null;
}

export const isValidHex = (s: string): boolean => {
  return s !== null && s !== undefined && s.match(HEX_REGEX) !== null;
}

export const isNumeric = (s: string): boolean => {
  return s !== null && s !== undefined && s.match(NUMERIC_REGEX) !== null;
}