import { useState, useContext, useEffect } from 'react';
import {
  TextInput,
  PasswordInput,
  Checkbox,
  Anchor,
  Paper,
  Title,
  Text,
  Container,
  Group,
  Button,
  Alert,
} from '@mantine/core';
import { useForm, isNotEmpty } from '@mantine/form';
import { IconAlertCircle } from '@tabler/icons-react';
import { ChallengeError, useAppService } from '../services/AppService';
import './Login.css';
import { RootContext } from '../contexts/RootContext';
import { IconLogin } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';

const LOCAL_STORAGE_LOGIN_KEY = 'login';

enum LoginStatus {
  READY,
  SUBMITTING,
  ERROR,
}

interface FormValues {
  username: string;
  password: string;
  rememberMe: boolean;
}

export function Login() {
  const context = useContext(RootContext);
  const appService = useAppService();
  const [loginStatus, setLoginStatus] = useState<LoginStatus>(LoginStatus.READY);
  const navigate = useNavigate();
  const form = useForm<FormValues>({
    validateInputOnBlur: true,
    initialValues: {
      username: '',
      password: '',
      rememberMe: false,
    },
    validate: {
      username: isNotEmpty('Username is required'),
      password: isNotEmpty('Password is required'),
    },
  });

  useEffect(() => {
    const username = localStorage.getItem(LOCAL_STORAGE_LOGIN_KEY);
    if (username) {
      form.setValues({username: username, rememberMe: true});
    }
  }, []);

  async function updateStoredLogin(rememberMe: boolean, username: string) {
    if (rememberMe) {
      localStorage.setItem(LOCAL_STORAGE_LOGIN_KEY, username);
    } else {
      localStorage.removeItem(LOCAL_STORAGE_LOGIN_KEY);
    }
  }

  async function onSubmit(values: any) {
    setLoginStatus(LoginStatus.SUBMITTING);
    try {
      let response = await appService.login(values.username, values.password);
      console.log('login response = ' + JSON.stringify(response));
      updateStoredLogin(values.rememberMe, values.username);
      context?.login(response.user, response.idToken, response.accessToken, response.refreshToken !== undefined ? response.refreshToken : null);
    } catch (e: any) {
      if (e instanceof ChallengeError) {
        navigate('/challenge', { state: { username: values.username }});
        return;
      }
      console.error(e);
      setLoginStatus(LoginStatus.ERROR);
    }
  };

  return (
    <Container size={620} my={40}>
      <Title ta="center">
        VOM Dashboard
      </Title>
      <Text c="dimmed" size="sm" ta="center" mt={5}>
        Don't have an account yet?{' '}
        <Anchor href="mailto:jsfelker@arizona.edu" size="sm">
          Contact us
        </Anchor>
      </Text>
      <Paper withBorder shadow="md" p={30} mt={30} radius="md">
        {loginStatus === LoginStatus.ERROR && (
          <Alert icon={<IconAlertCircle size="1rem" />} variant="outline" title="Sign in failed" color="red" mb="1.5rem">
            The username or password specified could not be authenticated. Please check your credentials and try again.
          </Alert>
        )}
        <form onSubmit={form.onSubmit((values) => onSubmit(values))}>
          <TextInput
            withAsterisk
            label="Username"
            placeholder="Enter username"
            {...form.getInputProps('username')}
          />
          <PasswordInput
            withAsterisk
            label="Password"
            placeholder="Enter your password"
            mt="md"
            {...form.getInputProps('password')}
          />
          <Group justify="space-between" mt="lg">
            <Checkbox label="Remember me" {...form.getInputProps('rememberMe', { type: 'checkbox' })} />
            <Anchor href="/forgot-password" size="sm">
              Forgot password?
            </Anchor>
          </Group>
          <Button type="submit" leftSection={<IconLogin size="1rem" />} disabled={!form.isValid()} loading={loginStatus === LoginStatus.SUBMITTING} fullWidth mt="xl">
            {loginStatus === LoginStatus.SUBMITTING ? 'Submitting...' : 'Sign in'}
          </Button>
        </form>
      </Paper>
    </Container>
  );
}