import { PageShell } from "../components/PageShell";
import {
  Tabs,
  Container,
} from "@mantine/core";
import { IconSettings } from "@tabler/icons-react";
import { useAppService } from "../services/AppService";

enum FormStatus {
  READY,
  SUBMITTING,
  ERROR,
  SUCCESS,
}

interface FormState {
  status: FormStatus;
  error?: string;
}

export function Settings() {
  const appService = useAppService();

  return (
    <PageShell title="Settings" activeNavLink="Settings">
      <Container size="md" mt={15}>
        <Tabs keepMounted={false} variant="outline"  defaultValue="avatar">
          <Tabs.List>
            <Tabs.Tab value="general" disabled={true} leftSection={<IconSettings size="1.0rem" />}>General Settings</Tabs.Tab>
          </Tabs.List>
          <Tabs.Panel value="general" pt="xs">
            Coming soon...
          </Tabs.Panel>
        </Tabs>
      </Container>
    </PageShell>
  );
}