import React, { useState } from "react";
import { PageShell } from "../components/PageShell";
import {
  Tabs,
  Container,
  Paper,
  Alert,
  PasswordInput,
  Notification,
  Button,
  Center,
  rem
} from "@mantine/core";
import { IconLock, IconAlertCircle, IconCheck, IconX } from "@tabler/icons-react";
import { useForm, hasLength, isNotEmpty } from "@mantine/form";
import { useAppService } from "../services/AppService";

enum FormStatus {
  READY,
  SUBMITTING,
  ERROR,
  SUCCESS,
}

interface PasswordFormValues {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
}

interface PasswordFormState {
  status: FormStatus;
  error?: string;
}

export function Account() {
  const appService = useAppService();
  const [passwordFormState, setPasswordFormState] = useState<PasswordFormState>({status: FormStatus.READY});
  const passwordForm = useForm<PasswordFormValues>({
    validateInputOnBlur: true,
    initialValues: {
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
    },
    validate: {
      oldPassword: isNotEmpty('Current password is required'),
      newPassword: hasLength({ min: 6 }, 'Password should contain at least 6 characters'),
      confirmPassword: (value, values) => value !== values.newPassword ? 'Passwords do not match' : null,
    },
  });

  async function onSubmit(values: any) {
    console.log(values);
    setPasswordFormState({status: FormStatus.SUBMITTING});
    try {
      await appService.updatePassword(values.oldPassword, values.newPassword);
      setPasswordFormState({status: FormStatus.SUCCESS});
      passwordForm.reset();
    } catch (e: any) {
      console.error(e);
      setPasswordFormState({status: FormStatus.ERROR, error: e.message});
    }
  };

  const xIcon = <IconX style={{ width: rem(20), height: rem(20) }} />;
  const checkIcon = <IconCheck style={{ width: rem(20), height: rem(20) }} />;
  
  return (
    <PageShell title="Account" activeNavLink="Account">
      <Container size="md" mt={15}>
        <Tabs variant="outline"  defaultValue="password">
          <Tabs.List>
            <Tabs.Tab value="password" leftSection={<IconLock size="1.0rem" />}>Change Password</Tabs.Tab>
          </Tabs.List>
          <Tabs.Panel value="password" pt="xs">
            <Paper withBorder shadow="md" p={20} mt={10} radius="md">
              {passwordFormState.status === FormStatus.SUCCESS && (
                <Notification withBorder mb={30} icon={checkIcon} color="teal" title="Success" mt="md">
                  Your password has been successfully updated.
                </Notification>
              )}
              {passwordFormState.status === FormStatus.ERROR && (
                <Notification withBorder mb={30} icon={xIcon} color="red" title="Error" mt="md">
                  Your password could not be changed. Please try again later.
                </Notification>
              )}
              <form onSubmit={passwordForm.onSubmit((values) => onSubmit(values))}>
                <PasswordInput
                  withAsterisk
                  label="Current Password"
                  placeholder="Enter your password"
                  mt="md"
                  {...passwordForm.getInputProps('oldPassword')}
                />
                <PasswordInput
                  withAsterisk
                  label="New Password"
                  placeholder="Enter your password"
                  mt="md"
                  {...passwordForm.getInputProps('newPassword')}
                />
                <PasswordInput
                  withAsterisk
                  label="Confirm Password"
                  placeholder="Enter your password"
                  mt="md"
                  {...passwordForm.getInputProps('confirmPassword')}
                />
                <Center>
                  <Button
                    type="submit"
                    leftSection={<IconLock size="1rem" />}
                    loading={passwordFormState.status === FormStatus.SUBMITTING}
                    mt="xl"
                  >
                    {passwordFormState.status === FormStatus.SUBMITTING ? 'Submitting...' : 'Save'}
                  </Button>
                </Center>
              </form>
            </Paper>
          </Tabs.Panel>
        </Tabs>
      </Container>
    </PageShell>
  );
}