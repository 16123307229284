import { PageShell } from "../components/PageShell";
import {
  ActionIcon,
  Anchor,
  Button,
  Center,
  Container,
  Fieldset,
  Grid,
  Group,
  Loader,
  MultiSelect,
  NativeSelect,
  Popover,
  TextInput,
  Textarea,
  Tooltip,
  Image,
  useMantineTheme,
  Notification,
  rem,
  Switch,
  Divider,
} from "@mantine/core";
import { IconArrowRight, IconCheck, IconCircle, IconFileImport, IconMap, IconPhoto, IconX } from "@tabler/icons-react";
import { useAppService } from "../services/AppService";
import { useForm } from "@mantine/form";
import { Coordinates, Dimension, InterestArea, LightType, LightTypes, OreDepositType, OreDepositTypes, Position, Reference, Sample, Scan } from "../types/Types";
import { useEffect, useState } from "react";
import { v4 as uuidv4 } from 'uuid';
import {
  isStringEmpty,
  isArrayEmpty,
  isValidDimension,
  isValidCoords,
  isValidPosition,
  isValidHex,
  isNumeric
} from "../utils/ValidationUtils";
import { Link, useSearchParams } from "react-router-dom";

enum FormStatus {
  LOADING,
  READY,
  SUBMITTING,
  ERROR,
  SUCCESS,
}

interface FormState {
  status: FormStatus;
  error?: string;
}

interface FormValues {
  id: string;
  name: string;
  dimensions: string;
  coordinates: string;
  maxZoom: string;
  oreColor: string;
  country: string;
  state: string;
  age: string;
  geology: string;
  mineralogy: string;
  lightTypes: string[];
  oreDepositType: string;
  relatedSamples: string[];
  factSheet: string;
  active: boolean;
  iaId0: string;
  iaPosition0: string;
  iaLightTypes0: string[];
  ia3600: string;
  iaId1: string;
  iaPosition1: string;
  iaLightTypes1: string[];
  ia3601: string;
  iaId2: string;
  iaPosition2: string;
  iaLightTypes2: string[];
  ia3602: string;
  iaId3: string;
  iaPosition3: string;
  iaLightTypes3: string[];
  ia3603: string;
  iaId4: string;
  iaPosition4: string;
  iaLightTypes4: string[];
  ia3604: string;
  iaId5: string;
  iaPosition5: string;
  iaLightTypes5: string[];
  ia3605: string;
  refTitle0: string;
  refLink0: string;
  refTitle1: string;
  refLink1: string;
  refTitle2: string;
  refLink2: string;
  refTitle3: string;
  refLink3: string;
  refTitle4: string;
  refLink4: string;
  refTitle5: string;
  refLink5: string;
  refTitle6: string;
  refLink6: string;
  refTitle7: string;
  refLink7: string;
  refTitle8: string;
  refLink8: string;
  refTitle9: string;
  refLink9: string;
}

interface InterestAreaData {
  x: number;
  y: number;
  preview?: string;
}

const FORM_SPACING = 30;
const MAX_IAS = 6;
const MAX_REFS = 10;
const DEFAULT_INTEREST_AREA_DATA = {x: -1, y: -1};
const DEFAULT_INTEREST_AREA_RADIUS = 50;
const PREVIEW_HEIGHT = 200;
const DEFAULT_PREVIEW_WIDTH = 338;

enum ScanType {
  BASE,
  INTEREST_AREA,
}

export enum Mode {
  ADD,
  EDIT,
}

export interface AddEditSampleProps {
  mode: Mode;
}

export function AddEditSample(props: AddEditSampleProps) {
  const { mode } = props;

  const [searchParams, setSearchParams] = useSearchParams();
  const id = searchParams.get('id');

  const appService = useAppService();
  const [formStatus, setFormStatus] = useState<FormStatus>(mode === Mode.EDIT || id !== null ? FormStatus.LOADING : FormStatus.READY);
  const [iaCount, setIaCount] = useState<number>(1);
  const [refCount, setRefCount] = useState<number>(1);
  const [interestAreaDatas, setInterestAreaDatas] = useState<InterestAreaData[]>(Array(MAX_IAS).fill(DEFAULT_INTEREST_AREA_DATA));
  const [currentSample, setCurrentSample] = useState<Sample | null>(null);
  const [relatedSamplesData, setRelatedSamplesData] = useState([{value: '-1', label: 'Loading...'}]);
  const theme = useMantineTheme();
  const form = useForm<FormValues>({
    validateInputOnBlur: true,
    initialValues: {
      id: uuidv4(),
      name: '',
      dimensions: '',
      coordinates: '',
      maxZoom: '3.0',
      oreColor: '#BEC4C7',
      country: '',
      state: '',
      age: '',
      geology: '',
      mineralogy: '',
      lightTypes: [],
      oreDepositType: '' + OreDepositType[0],
      relatedSamples: [],
      factSheet: '',
      active: false,
      iaId0: uuidv4(),
      iaPosition0: '',
      iaLightTypes0: [],
      ia3600: 'No',
      iaId1: uuidv4(),
      iaPosition1: '',
      iaLightTypes1: [],
      ia3601: 'No',
      iaId2: uuidv4(),
      iaPosition2: '',
      iaLightTypes2: [],
      ia3602: 'No',
      iaId3: uuidv4(),
      iaPosition3: '',
      iaLightTypes3: [],
      ia3603: 'No',
      iaId4: uuidv4(),
      iaPosition4: '',
      iaLightTypes4: [],
      ia3604: 'No',
      iaId5: uuidv4(),
      iaPosition5: '',
      iaLightTypes5: [],
      ia3605: 'No',
      refTitle0: '',
      refLink0: '',
      refTitle1: '',
      refLink1: '',
      refTitle2: '',
      refLink2: '',
      refTitle3: '',
      refLink3: '',
      refTitle4: '',
      refLink4: '',
      refTitle5: '',
      refLink5: '',
      refTitle6: '',
      refLink6: '',
      refTitle7: '',
      refLink7: '',
      refTitle8: '',
      refLink8: '',
      refTitle9: '',
      refLink9: '',
    },
    validate: (values) => {
      //console.log('values = ' + JSON.stringify(values));
      let errors: {[key: string]: string} = {};
      const vs = form.getValues() as any;
      if (isStringEmpty(vs['id'])) {
        errors['id'] = 'ID is required';
      }
      if (isStringEmpty(vs['name'])) {
        errors['name'] = 'Name is required';
      }
      if (!isValidDimension(vs['dimensions'])) {
        errors['dimensions'] = 'Valid dimensions is required';
      }
      if (!isValidCoords(vs['coordinates'])) {
        errors['coordinates'] = 'Valid coordinates is required';
      }
      if (!isNumeric(vs['maxZoom'])) {
        errors['maxZoom'] = 'Valid number is required';
      }
      if (!isValidHex(vs['oreColor'])) {
        errors['oreColor'] = 'Valid color is required';
      }
      if (isStringEmpty(vs['country'])) {
        errors['country'] = 'Country is required';
      }
      if (isStringEmpty(vs['state'])) {
        errors['state'] = 'State is required';
      }
      if (isStringEmpty(vs['age'])) {
        errors['age'] = 'Age is required';
      }
      // if (isStringEmpty(vs['geology'])) {
      //   errors['geology'] = 'Geology is required';
      // }
      // if (isStringEmpty(vs['mineralogy'])) {
      //   errors['mineralogy'] = 'Mineralogy is required';
      // }
      if (isArrayEmpty(vs['lightTypes'])) {
        errors['lightTypes'] = 'Light types is required';
      }
      if (isStringEmpty(vs['oreDepositType'])) {
        errors['oreDepositType'] = 'Ore deposit type is required';
      }
      if (isStringEmpty(vs['factSheet'])) {
        errors['factSheet'] = 'Fact sheet is required';
      }
      for (let i = 0; i < iaCount; i++) {
        if (isStringEmpty(vs['iaId' + i])) {
          errors['iaId' + i] = 'ID is required';
        }
        if (!isValidPosition(vs['iaPosition' + i])) {
          errors['iaPosition' + i] = 'Position is required';
        }
        if (isArrayEmpty(vs['iaLightTypes' + i])) {
          errors['iaLightTypes' + i] = 'Light types is required';
        }
        if (isStringEmpty(vs['ia360' + i])) {
          errors['ia360' + i] = '360 is required';
        }
      }
      for (let i = 0; i < refCount; i++) {
        if (isStringEmpty(vs['refTitle' + i])) {
          errors['refTitle' + i] = 'Title is required';
        }
      }
      //console.log('errors = ' + JSON.stringify(errors));
      return (errors);
    },
  });

  const getBaseScanDescription = (lightType: LightType): string => {
    if (currentSample !== null) {
      for (const scan of currentSample.scans) {
        if (scan.lightType === lightType && scan.description) {
          return scan.description;
        }
      }
    }
    return '';
  }

  const getInterestAreaScanDescription = (id: string, lightType: LightType): string => {
    if (currentSample !== null && currentSample.interestAreas) {
      for (const interestArea of currentSample.interestAreas) {
        if (interestArea.id === id) {
          for (const scan of currentSample.scans) {
            if (scan.lightType === lightType && scan.description) {
              return scan.description;
            }
          }
        }
      }
    }
    return '';
  }

  useEffect(() => {
    (async function() {
      try {
        const samples = await appService.getSamples();
        if (samples === null) {
          console.warn('Could not get samples');
          return;
        }
        const data = samples.filter((s: any) => s.name).map((s: any) => ({value: s.sk ? s.sk : s.id, label: s.name}));
        setRelatedSamplesData(data);
        if (id !== null) {
          const sample = await appService.getSample(id);
          if (sample === null) {
            console.warn('Could not get sample');
            return;
          }
          console.log('got edit/clone sample = ' + JSON.stringify(sample));
          if (mode === Mode.EDIT) {
            form.setFieldValue('id', sample.id);
          }
          form.setFieldValue('name', sample.name);
          form.setFieldValue('dimensions', sample.defaultDimension.width + ' x ' + sample.defaultDimension.height);
          form.setFieldValue('coordinates', sample.coordinates.latitude + ',' + sample.coordinates.longitude);
          form.setFieldValue('maxZoom', '' + sample.maxZoom);
          form.setFieldValue('oreColor', sample.oreColor);
          form.setFieldValue('country', sample.country);
          form.setFieldValue('state', sample.state);
          form.setFieldValue('age', sample.age);
          form.setFieldValue('geology', sample.geology);
          form.setFieldValue('mineralogy', sample.mineralogy);
          form.setFieldValue('factSheet', sample.factSheet);
          form.setFieldValue('active', sample.active);
          form.setFieldValue('oreDepositType', OreDepositType[sample.oreDepositType]);
          form.setFieldValue('lightTypes', sample.scans.map(sc => '' + LightType[sc.lightType]));
          form.setFieldValue('active', sample.active);
          sample.interestAreas?.forEach((ia, i) => {
            form.setFieldValue('iaId' + i, ia.id);
            form.setFieldValue('iaPosition' + i, ia.position.x + ',' + ia.position.y);
            form.setFieldValue('iaLightTypes' + i, ia.scans.map(sc => LightType[sc.lightType]));
            form.setFieldValue('ia360' + i, ia.threeSixty ? 'Yes' : 'No');
          });
          setIaCount(sample.interestAreas ? sample.interestAreas.length : 0);
          sample.references?.forEach((r, i) => {
            form.setFieldValue('refTitle' + i, r.title);
            form.setFieldValue('refLink' + i, r.link);
          });
          setRefCount(sample.references ? sample.references.length : 0);
          if (mode === Mode.EDIT) {
            setCurrentSample(sample);
          }
        }
        setFormStatus(FormStatus.READY);
      } catch (e) {
        console.error(e);
      }
    })();
  }, [])

  const onInterestAreaClick = (index: number, e: React.MouseEvent<HTMLDivElement>) => {
    const node = e.target as HTMLElement
    const rect = node.getBoundingClientRect();
    const x = Math.max(e.clientX - rect.left, 0);
    const y = Math.max(e.clientY - rect.top, 0); 
    console.log('x = ' + x + ', y = ' + y);
    setInterestAreaDatas(interestAreaDatas.map((ia, i) => {
      return i === index ? {preview: ia.preview, x, y} : ia;
    }));
    form.setFieldValue('iaPosition' + index, x.toFixed(2) + ',' + y.toFixed(2));
  };

  const onAddInterestAreaClick = () => {
    if (iaCount >= MAX_IAS) {
      return;
    }
    form.setFieldValue('iaId' + iaCount, uuidv4());
    form.setFieldValue('iaPosition' + iaCount, '');
    form.setFieldValue('iaLightTypes' + iaCount, []);
    form.setFieldValue('ia360' + iaCount, 'No');
    setInterestAreaDatas(interestAreaDatas.map((ia, i) => {
      return i === iaCount ? DEFAULT_INTEREST_AREA_DATA : ia;
    }));
    setIaCount(iaCount + 1);
  };

  const onRemoveInterestAreaClick = (index: number) => {
    for (let i = index; i < MAX_IAS; i++) {
      if (i >= iaCount - 1) {
        form.setFieldValue('iaId' + i, '');
        form.setFieldValue('iaPosition' + i, '');
        form.setFieldValue('iaLightTypes' + i, []);
        form.setFieldValue('ia360' + i, 'No');
      } else {
        form.setFieldValue('iaId' + i, getFormValue('iaId' + (i + 1)));
        form.setFieldValue('iaPosition' + i, getFormValue('iaPosition' + (i + 1)));
        form.setFieldValue('iaLightTypes' + i, getFormValue('iaLightTypes' + (i + 1)));
        form.setFieldValue('ia360' + i, getFormValue('ia360' + (i + 1)));
      }
    }
    setInterestAreaDatas(interestAreaDatas.map((ia, i) => {
      return i >= iaCount - 1 ? DEFAULT_INTEREST_AREA_DATA : ia;
    }));
    setIaCount(iaCount - 1);
  };

  const onAddReferenceClick = () => {
    if (refCount >= MAX_REFS) {
      return;
    }
    form.setFieldValue('refTitle' + refCount, '');
    form.setFieldValue('refLink' + refCount, '')
    setRefCount(refCount + 1);
  };

  const onRemoveReferenceClick = (index: number) => {
    for (let i = index; i < MAX_REFS; i++) {
      if (i >= iaCount - 1) {
        form.setFieldValue('refTitle' + i, '');
        form.setFieldValue('refLink' + i, '')
      } else {
        form.setFieldValue('refTitle' + i, getFormValue('refTitle' + (i + 1)));
        form.setFieldValue('refLink' + i, getFormValue('refLink' + (i + 1)))
      }
    }
    setRefCount(refCount - 1);
  };

  const onPreviewClick = (index: number, e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    const input = document.getElementById('previewInput');
    if (input !== null) {
      input.click();
      input.addEventListener("change", (e: any) => {
        const files = e.target.files;
        if (files.length > 0) {
          const url = URL.createObjectURL(files[0]);
          console.log('url = ' + url);
          setInterestAreaDatas(interestAreaDatas.map((ia, i) => {
            return i === index ? {preview: url, x: ia.x, y: ia.y} : ia;
          }));
        }
      });
    }
  };

  const getFormValue = (key: string): any => {
    const values = form.getValues() as any;
    if (key in values) {
      const v = values[key];
      if (v !== null && v !== undefined) {
        return v;
      }
    }
    return '';
  };

  const parseDimensions = (value: string): Dimension => {
    const parts = value.split('x');
    return {width: parseInt(parts[0].trim()), height: parseInt(parts[1].trim())};
  }

  const parsePosition = (value: string): Position => {
    const parts = value.split(',');
    return {x: parseFloat(parts[0].trim()), y: parseFloat(parts[1].trim())};
  }

  const parseCoords = (value: string): Coordinates => {
    const parts = value.split(',');
    return {latitude: parseFloat(parts[0].trim()), longitude: parseFloat(parts[1].trim())};
  }

  const onCoordsClick = () => {
    const coords = getFormValue('coordinates');
    if (coords === '') {
      return;
    }
    window.open('https://www.google.com/maps/place/' + coords);
  }

  const buildBaseScans = (lightTypes: string[]): Scan[] => {
    let scans: Scan[] = [];
    for (let i = 0; i < lightTypes.length; i++) {
      const lightType = LightType[lightTypes[i] as keyof typeof LightType];
      scans.push({
        lightType: lightType,
        description: getBaseScanDescription(lightType),
      });
    }
    return scans;
  }

  const buildInterestAreaScans = (id: string, lightTypes: string[]): Scan[] => {
    let scans: Scan[] = [];
    for (let i = 0; i < lightTypes.length; i++) {
      const lightType = LightType[lightTypes[i] as keyof typeof LightType];
      scans.push({
        lightType: lightType,
        description: getInterestAreaScanDescription(id, lightType),
      });
    }
    return scans;
  }

  const buildInterestArea = (values: any, index: number): InterestArea => {
    const position = parsePosition(values['iaPosition' + index]);
    const id = values['iaId' + index];
    let interestArea: InterestArea = {
      id: id,
      position: {
        x: position.x,
        y: position.y,
      },
      radius: DEFAULT_INTEREST_AREA_RADIUS,
      title: '',
      description: '',
      scans: buildInterestAreaScans(id, values['iaLightTypes' + index]),
      threeSixty: values['ia360' + index] === 'Yes' ? true : false,
    }
    return interestArea;
  }

  const buildReference = (values: any, index: number): Reference => {
    let reference: Reference = {
      title: values['refTitle' + index],
      link: values['refLink' + index],
    }
    return reference;
  }

  const calcAdjustedWidth = (height: number): number | null => {
    const value = getFormValue('dimensions');
    if (value === null || value === '') {
      return null;
    }
    const parts = value.split('x');
    if (parts.length !== 2) {
      return null;
    }
    const originalWidth = parseInt(parts[0].trim());
    const originalHeight = parseInt(parts[1].trim());
    return (originalWidth / originalHeight) * height;
  }

  const buildSample = (values: typeof form.values) => {
    const dimensions = parseDimensions(values.dimensions);
    const coords = parseCoords(values.coordinates);
    let interestAreaDatas: InterestArea[] = [];
    for (let i = 0; i < iaCount; i++) {
      interestAreaDatas.push(buildInterestArea(values as any, i));
    }
    let referenceDatas: Reference[] = [];
    for (let i = 0; i < refCount; i++) {
      referenceDatas.push(buildReference(values as any, i));
    }
    let sample: Sample = {
      id: values.id,
      name: values.name,
      defaultDimension: {
        width: dimensions.width,
        height: dimensions.height,
      },
      maxZoom: parseFloat(values.maxZoom),
      relatedSamples: [],
      coordinates: {
        latitude: coords.latitude,
        longitude: coords.longitude,
      },
      references: referenceDatas,
      scans: buildBaseScans(values.lightTypes),
      oreColor: values.oreColor,
      country: values.country,
      state: values.state,
      oreDepositType: OreDepositType[values.oreDepositType as keyof typeof OreDepositType],
      factSheet: values.factSheet,
      geology: values.geology,
      mineralogy: values.mineralogy,
      age: values.age,
      interestAreas: interestAreaDatas,
      active: values.active,
    };
    return sample;
  }

  const handleSubmit = async (values: typeof form.values) => {
    setFormStatus(FormStatus.SUBMITTING);
    try{
      console.log('values = ' + JSON.stringify(values));
      const sample = buildSample(values);
      if (mode === Mode.EDIT && currentSample !== null) {
        await appService.updateSample(currentSample.id, sample);
      } else {
        await appService.createSample(sample);
      }
      setFormStatus(FormStatus.SUCCESS);
    } catch (e) {
      setFormStatus(FormStatus.ERROR);
    }
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  };

  const handleErrors = (errors: typeof form.errors) => {
    console.log('errors = ' + JSON.stringify(errors));
  }

  if (formStatus === FormStatus.LOADING) {
    return (
      <PageShell title={mode === Mode.ADD ? 'Add Sample' : 'Edit Sample'} activeNavLink="Samples">
        <Container size="lg" mt={5} mb={30}>
          <Center>
            <Loader mt={50} size="md" variant="bars" />
          </Center>
        </Container>
      </PageShell>
    )
  }

  const xIcon = <IconX style={{ width: rem(20), height: rem(20) }} />;
  const checkIcon = <IconCheck style={{ width: rem(20), height: rem(20) }} />;
  
  return (
    <PageShell title={mode === Mode.ADD ? 'Add Sample' : 'Edit Sample'} activeNavLink="Samples">
      <Container size="lg" mt={5} mb={30}>
        {formStatus === FormStatus.ERROR && (
          <Notification withBorder mb={30} icon={xIcon} color="red" title="Error" mt="md">
            The sample could not be saved. Please try again later.
          </Notification>
        )}
        {formStatus === FormStatus.SUCCESS && (
          <Notification withBorder mb={30} icon={checkIcon} color="teal" title="Success" mt="md">
            The sample was saved successfully. You may return the <Link to={'/samples'}>sample list</Link> now.
          </Notification>
        )}
        <Group justify="flex-end">
          {mode === Mode.EDIT && (
            <Link to={'/sample-images?id=' + currentSample?.id}>
              <Tooltip label="Images" color="gray">
                <ActionIcon variant="filled" size="lg" aria-label="Images">
                  <IconPhoto />
                </ActionIcon>
              </Tooltip>
            </Link>
          )}
          {/*<Tooltip label="Import" color="gray">
            <ActionIcon onClick={() => {}} size="lg" variant="filled" mr={5}>
              <IconFileImport />
            </ActionIcon>
          </Tooltip>*/}
        </Group>
        <form onSubmit={form.onSubmit(handleSubmit, handleErrors)}>
          <Fieldset legend="Basic data">
            <Group grow mb={FORM_SPACING} align="flex-start">
              <TextInput
                withAsterisk
                label="ID"
                description="The sample's ID"
                {...form.getInputProps('id')}
              />
              <TextInput
                withAsterisk
                label="Name"
                description="The sample's name"
                {...form.getInputProps('name')}
              />
            </Group>
            <Group grow mb={FORM_SPACING} align="flex-start">
              <TextInput
                withAsterisk
                label="Dimensions"
                description="width x height"
                {...form.getInputProps('dimensions')}
              />
              <TextInput
                withAsterisk
                label="Coordinates"
                description="lat,long"
                rightSection={<IconMap cursor="pointer" onClick={onCoordsClick}/>}
                {...form.getInputProps('coordinates')}
              />
              <TextInput
                readOnly={true}
                withAsterisk
                label="Max Zoom"
                description="The maximum zoom (e.g. 3x)"
                {...form.getInputProps('maxZoom')}
              />
            </Group>
            <Group grow mb={FORM_SPACING} align="flex-start">
              <TextInput
                withAsterisk
                label="Ore Color"
                description="Hex code value (e.g. #BEC4C7)"
                {...form.getInputProps('oreColor')}
              />
              <TextInput
                withAsterisk
                label="Country"
                description="The sample's country of origin"
                {...form.getInputProps('country')}
              />
              <TextInput
                withAsterisk
                label="State"
                description="The sample's state of origin"
                {...form.getInputProps('state')}
              />
            </Group>
            <Group grow mb={FORM_SPACING} align="flex-start">
              <TextInput
                withAsterisk
                label="Age"
                description="The sample's age"
                {...form.getInputProps('age')}
              />
              <TextInput
                // withAsterisk
                label="Host-Rock Geology"
                description="The sample's host-rock geology"
                {...form.getInputProps('geology')}
              />
              <TextInput
                // withAsterisk
                label="Main Ore Mineralogy"
                description="The sample's main ore mineralogy"
                {...form.getInputProps('mineralogy')}
              />
            </Group>
            <Group grow mb={FORM_SPACING} align="flex-start">
              <MultiSelect
                
                label="Light Types"
                withAsterisk
                description="Select one or more light types"
                data={LightTypes.map(lt => LightType[lt])}
                //value={sample !== null ? sample.lightTypes.map(lt => LightType[lt]) : []}
                {...form.getInputProps('lightTypes')}
              />
              <NativeSelect
                label="Ore Deposit Type"
                withAsterisk
                description="Select an ore deposit type"
                data={OreDepositTypes.map(odt => OreDepositType[odt])}
                {...form.getInputProps('oreDepositType')}
              />
              <MultiSelect
                label="Related Samples"
                description="Select related samples"
                data={relatedSamplesData}
                {...form.getInputProps('relatedSamples')}
              />
            </Group>
            <Group grow mb={FORM_SPACING}  align="flex-start">
              <Textarea
                label="Fact Sheet"
                withAsterisk
                description="Fact sheet information"
                autosize
                minRows={10}
                {...form.getInputProps('factSheet')}
              />
            </Group>
            <Group align="flex-start">
              <Switch
                defaultChecked={currentSample?.active}
                label={'Active (will sample appear in the app)?'}
                {...form.getInputProps('active')}
              />
            </Group>
          </Fieldset>
          <Fieldset mt={20} legend="Interest areas">
            {Array.apply(0, Array(MAX_IAS)).map(function (x, i) {
              return (
                <>
                  <Grid align="flex-start" mb={20} display={i < iaCount ? 'block' : 'none'}>
                    <Grid.Col span={4}>
                      <TextInput
                        withAsterisk
                        label="ID"
                        description="The interest area's ID"
                        {...form.getInputProps('iaId' + i)}
                      />
                    </Grid.Col>
                    <Grid.Col span={2}>
                      <Popover position="bottom" withArrow shadow="md">
                        <Popover.Target>
                          <TextInput
                            withAsterisk
                            label="Position"
                            description="x,y"
                            {...form.getInputProps('iaPosition' + i)}
                          />
                        </Popover.Target>
                        <Popover.Dropdown>
                          <div onClick={(e) => onInterestAreaClick(i, e)} style={{width: calcAdjustedWidth(PREVIEW_HEIGHT) || DEFAULT_PREVIEW_WIDTH, height: PREVIEW_HEIGHT, backgroundColor: theme.colors.gray[6]}}>
                            {interestAreaDatas.length >= i && interestAreaDatas[i].preview && (
                              <Image
                                w={'100%'}
                                h={'100%'}
                                src={interestAreaDatas[i].preview}
                              />
                            )}
                            {interestAreaDatas.filter(p => p.x >= 0 && p.y >= 0).map((p, j) => (
                              <IconCircle
                                fill={j === i ? 'rgba(255, 255, 255, 0.8)' : 'rgba(255, 255, 255, 0.5)'}
                                color={'rgba(0, 0, 0, 0)'}
                                style={{position: 'absolute', left: p.x, top: p.y}} />
                            ))}
                            <Tooltip label="Preview" color="gray">
                              <ActionIcon style={{position: 'absolute', top: 15, right: 20}} onClick={(e) => onPreviewClick(i, e)} size="sm" variant="filled">
                                <IconPhoto size={20} />
                              </ActionIcon>
                            </Tooltip>
                          </div>
                        </Popover.Dropdown>
                      </Popover>
                    </Grid.Col>
                    <Grid.Col span={3}>
                      <MultiSelect
                        label="Light Types"
                        description="Select one or more light types"
                        data={LightTypes.map(lt => LightType[lt])}
                        withAsterisk
                        {...form.getInputProps('iaLightTypes' + i)}
                      />
                    </Grid.Col>
                    <Grid.Col span={2}>
                      <NativeSelect
                        label="360°?"
                        withAsterisk
                        description="Is this a 360° ZoI?"
                        data={['Yes', 'No']}
                        {...form.getInputProps('ia360' + i)}
                      />
                    </Grid.Col>
                    <Grid.Col mt={47} span={1}>
                      <Tooltip label="Delete" color="gray">
                        <ActionIcon ml={20} onClick={() => onRemoveInterestAreaClick(i)} size={25} variant="filled">
                          <IconX />
                        </ActionIcon>
                      </Tooltip>
                    </Grid.Col>
                  </Grid>
                  <Divider my="md" display={i < iaCount - 1 ? 'block' : 'none'} />
                </>
              );
            })}
            {iaCount < MAX_IAS && <Anchor pb={0} href="#" onClick={onAddInterestAreaClick}>Add an interest area?</Anchor>}
            <input id="previewInput" type="file" accept="image/jpeg" style={{display: 'none'}} />
          </Fieldset>
          <Fieldset mt={20} legend="References">
            {Array.apply(0, Array(MAX_REFS)).map(function (x, i) {
              return (
                <Grid align="flex-start" mb={20} display={i < refCount ? 'block' : 'none'}>
                  <Grid.Col span={5}>
                    <TextInput
                      withAsterisk
                      label="Title"
                      description="The reference's title"
                      {...form.getInputProps('refTitle' + i)}
                    />
                  </Grid.Col>
                  <Grid.Col span={6}>
                    <TextInput
                      label="Link"
                      description="The reference's link"
                      {...form.getInputProps('refLink' + i)}
                    />
                  </Grid.Col>
                  <Grid.Col mt={47} span={1}>
                    <Tooltip label="Delete" color="gray">
                      <ActionIcon ml={20} onClick={() => onRemoveReferenceClick(i)} size={25} variant="filled">
                        <IconX />
                      </ActionIcon>
                    </Tooltip>
                  </Grid.Col>
                </Grid>
              );
            })}
            {refCount < MAX_REFS && <Anchor pb={0} href="#" onClick={onAddReferenceClick}>Add a reference?</Anchor>}
          </Fieldset>
          <Group mt={FORM_SPACING}>
            <Button type="submit" leftSection={<IconArrowRight size="1rem" />} loading={formStatus === FormStatus.SUBMITTING} fullWidth>
              {formStatus === FormStatus.SUBMITTING ? 'Submitting...' : 'Submit'}
            </Button>
          </Group>
        </form>
      </Container>
    </PageShell>
  );
}
