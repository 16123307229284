import React from 'react';
import { API_BASE_URL, CLIENT_ID, USER_POOL_ID } from '../Constants';
import { RootContext } from '../contexts/RootContext';
import { User } from '../types/User';
import { InterestArea, LightType, LightTypes, OreDepositTypes, Reference, Sample, Scan } from '../types/Types';
import { 
  AuthFlowType,
  AuthenticationResultType,
  CognitoIdentityProviderClient,
  InitiateAuthCommand,
  RespondToAuthChallengeCommand,
  ChangePasswordCommand,
  ForgotPasswordCommand,
  ConfirmForgotPasswordCommand,
} from '@aws-sdk/client-cognito-identity-provider';
import { CognitoJwtVerifier } from "aws-jwt-verify";
import axios from 'axios';

const accessVerifier = CognitoJwtVerifier.create({
  userPoolId: USER_POOL_ID,
  tokenUse: 'access',
  clientId: CLIENT_ID,
});

const idVerifier = CognitoJwtVerifier.create({
  userPoolId: USER_POOL_ID,
  tokenUse: 'id',
  clientId: CLIENT_ID,
});

export interface LoginData {
  idToken: string;
  accessToken: string;
  refreshToken: string | undefined;
  user: User;
}

export class ChallengeError extends Error {
  constructor(message: string) {
    super(message);
    this.name = "ChallengeError";
  }
}

let currentSession: string | null = null;

export function useAppService() {
  const context = React.useContext(RootContext);
  const client = new CognitoIdentityProviderClient({region: 'us-west-2'});

  async function login(username: string, password: string): Promise<LoginData> {
    const authCommand = new InitiateAuthCommand({
      AuthFlow: AuthFlowType.USER_PASSWORD_AUTH,
      AuthParameters: {
        USERNAME: username,
        PASSWORD: password,
      },
      ClientId: CLIENT_ID,
    });
    let authResponse = await client.send(authCommand);
    console.log('auth response = ' + JSON.stringify(authResponse));
    if (authResponse.ChallengeName && authResponse.ChallengeName === 'NEW_PASSWORD_REQUIRED') {
      if (!authResponse.Session) {
        throw new Error('Could not find challenge session');
      } else {
        currentSession = authResponse.Session;
      }
      throw new ChallengeError('New password required');
    }
    if (!authResponse.AuthenticationResult) {
      throw new Error('Could not find auth result');
    }
    return await processAuthResult(authResponse.AuthenticationResult);
  }

  async function processAuthResult(result: AuthenticationResultType): Promise<LoginData> {
    if (!result.AccessToken || !result.IdToken) {
      throw new Error('Could not find auth tokens');
    }
    let payload;
    try {
      payload = await idVerifier.verify(result.IdToken);
    } catch (e) {
      throw new Error('Invalid token')
    }
    return {
      accessToken: result.AccessToken,
      idToken: result.IdToken,
      refreshToken: result.RefreshToken,
      user: {id: payload.sub, email: String(payload.email), roles: []},
    }
  }

  async function respondToChallenge(username: string, newPassword: string): Promise<LoginData> {
    if (!currentSession) {
      throw new Error('Could not find challenge session');
    }
    const challengeCommand = new RespondToAuthChallengeCommand({
      ChallengeName: 'NEW_PASSWORD_REQUIRED',
      ChallengeResponses: {
        USERNAME: username,
        NEW_PASSWORD: newPassword
      },
      Session: currentSession,
      ClientId: CLIENT_ID,
    });
    let challengeResponse = await client.send(challengeCommand);
    console.log('challenge response = ' + JSON.stringify(challengeResponse));
    if (!challengeResponse.AuthenticationResult) {
      throw new Error('Could not find auth result');
    }
    return await processAuthResult(challengeResponse.AuthenticationResult);
  }

  async function updatePassword(oldPassword: string, newPassword: string) {
    const command = new ChangePasswordCommand({
      AccessToken: getAccessToken(),
      PreviousPassword: oldPassword,
      ProposedPassword: newPassword,
    })
    await client.send(command);
  }

  async function forgotPassword(username: string) {
    const command = new ForgotPasswordCommand({
      Username: username,
      ClientId: CLIENT_ID,
    })
    const response = await client.send(command);
    console.log('forgot password response = ' + JSON.stringify(response));
  }

  async function confirmForgotPassword(code: string, username: string, password: string) {
    const command = new ConfirmForgotPasswordCommand({
      ConfirmationCode: code,
      Username: username,
      Password: password,
      ClientId: CLIENT_ID,
    })
    const response = await client.send(command);
    console.log('forgot password response = ' + JSON.stringify(response));
  }

  async function checkToken(token: string): Promise<boolean> {
    console.log('checking token');
    return true;
  }

  async function uploadImage(fileName: string, file: File, progressFn?: (percent: number) => void): Promise<void> {
    console.log('uploading image');
    const response = await fetch(`${API_BASE_URL}upload-url`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Authorization': getIdToken(),
      },
      body: JSON.stringify({
        fileName: fileName,
      })
    });
    console.log('upload file status = ' + response.status);
    const data = await response.json();
    if (!response.ok) {
      if (response.status === 401) {
        context?.logout();
        return;
      }
      throw new Error("API request failed")
    }
    console.log('data = ' + JSON.stringify(data));
    const uploadResponse = await axios.put(data.url, file, {
      headers: {
        "Content-Type": "application/jpeg",
      },
      onUploadProgress: (progressEvent) => {
        if (progressFn && progressEvent.total !== undefined) {
          progressFn((progressEvent.loaded * 100) / progressEvent.total);
        }
      },
    });
    console.log('upload response = ' + JSON.stringify(uploadResponse));
  }

  async function getSamples(): Promise<Sample[] | null> {
    console.log('getting samples');
    const response = await fetch(`${API_BASE_URL}samples`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Authorization': getIdToken(),
      },
    });
    console.log('status = ' + response.status);
    if (!response.ok) {
      if (response.status === 401) {
        context?.logout();
        return null;
      }
      throw new Error("API request failed")
    }
    const data = await response.json();
    console.log('response = ' + JSON.stringify(data));
    return data.map(convertSample);
  };

  async function getSample(id: string): Promise<Sample | null> {
    console.log('getting sample');
    const response = await fetch(`${API_BASE_URL}sample?id=${id}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Authorization': getIdToken(),
      },
    });
    if (!response.ok) {
      if (response.status === 401) {
        context?.logout();
        return null;
      }
      throw new Error("API request failed")
    }
    const data = await response.json();
    console.log('response = ' + JSON.stringify(data));
    return convertSample(data);
  };

  async function createSample(sample: Sample): Promise<void> {
    console.log('creating sample');
    const response = await fetch(`${API_BASE_URL}sample`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': getIdToken(),
      },
      body: JSON.stringify(sample),
    });
    if (!response.ok) {
      if (response.status === 401) {
        context?.logout();
        return;
      }
      throw new Error("API request failed")
    }
  }

  async function updateSample(id: string, sample: Sample): Promise<void> {
    console.log('updating sample');
    const response = await fetch(`${API_BASE_URL}sample?id=${id}`, {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': getIdToken(),
      },
      body: JSON.stringify(sample),
    });
    if (!response.ok) {
      if (response.status === 401) {
        context?.logout();
        return;
      }
      throw new Error("API request failed")
    }
  }

  async function updateBaseScanDescription(sampleId: string, lightType: LightType, description: string): Promise<void> {
    console.log('updating base scan desc, lt = ' + lightType);
    const response = await fetch(`${API_BASE_URL}sample/description?id=${sampleId}&lt=${lightType}`, {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': getIdToken(),
      },
      body: JSON.stringify({
        description,
      }),
    });
    if (!response.ok) {
      if (response.status === 401) {
        context?.logout();
        return;
      }
      throw new Error("API request failed")
    }
  }

  async function updateInterestAreaScanDescription(sampleId: string, interestAreaId: string, lightType: LightType, description: string): Promise<void> {
    console.log('updating interest area scan desc, lt = ' + lightType);
    const response = await fetch(`${API_BASE_URL}sample/interestArea/description?id=${sampleId}&aid=${interestAreaId}&lt=${lightType}`, {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': getIdToken(),
      },
      body: JSON.stringify({
        description,
      }),
    });
    if (!response.ok) {
      if (response.status === 401) {
        context?.logout();
        return;
      }
      throw new Error("API request failed")
    }
  }

  async function deleteSample(id: string): Promise<void> {
    console.log('deleting sample');
    const response = await fetch(`${API_BASE_URL}sample?id=${id}`, {
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
        'Authorization': getIdToken(),
      },
    });
    if (!response.ok) {
      if (response.status === 401) {
        context?.logout();
        return;
      }
      throw new Error("API request failed")
    }
  };

  function getIdToken(): string {
    if (!context || !context.state || context.state.idToken === undefined || context.state.idToken === null || context.state.idToken === '') {
      throw new Error('Unable to find valid auth token');
    }
    return context.state.idToken;
  }

  function getAccessToken(): string {
    if (!context || !context.state || context.state.accessToken === undefined || context.state.accessToken === null || context.state.accessToken === '') {
      throw new Error('Unable to find valid auth token');
    }
    console.log('access token = ' + context.state.accessToken);
    return context.state.accessToken;
  }

  function getBase64fromFile(file: File): Promise<{header: string, body: string}> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        if (reader.result === null || typeof(reader.result) != 'string') {
          reject('result not found');
          return;
        }
        console.log(`getBase64fromFile success.`);
        const spliced = reader.result.split(',');
        const header = spliced[0];
        spliced.shift();
        resolve({
          header: header,
          body: spliced.join('')
        });
      };
      reader.onerror = (err) => {
        reject(err);
      };
    });
  }

  function convertSample(data: any): Sample {
    const result: Sample = {
      age: data.age,
      id: data.id,
      name: data.name,
      defaultDimension: data.defaultDimension,
      maxZoom: data.maxZoom,
      relatedSamples: [],
      coordinates: data.coordinates,
      scans: data.scans.map(convertScan),
      oreColor: data.oreColor,
      country: data.country,
      state: data.state,
      oreDepositType: OreDepositTypes[data.oreDepositType],
      factSheet: data.factSheet,
      geology: data.geology,
      mineralogy: data.mineralogy,
      interestAreas: data.interestAreas.map(convertInterestArea),
      references: data.references.map(convertReference),
      active: data.active,
    };
    return result;
  }

  function convertInterestArea(data: any): InterestArea {
    const result: InterestArea = {
      id: data.id,
      position: data.position,
      title: '',
      description: data.description,
      scans: data.scans.map(convertScan),
      threeSixty: data.threeSixty,
      radius: data.radius,
    }
    return result;
  }

  function convertScan(data: any): Scan {
    const result: Scan = {
      lightType: LightTypes[data.lightType],
      description: data.description,
    }
    return result;
  }

  function convertReference(data: any): Reference {
    const result: Reference = {
      title: data.title,
      link: data.link,

    }
    return result;
  }

  return {
    login,
    updatePassword,
    forgotPassword,
    confirmForgotPassword,
    checkToken,
    getSamples,
    getSample,
    createSample,
    updateSample,
    deleteSample,
    uploadImage,
    updateBaseScanDescription,
    updateInterestAreaScanDescription,
    respondToChallenge,
  };
}
