import { useMantineColorScheme } from '@mantine/core';
import '@mantine/core/styles.css';
import {
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { Home } from './pages/Home';
import { Login } from './pages/Login';
import { useReducer, useEffect, useState, useRef } from 'react';
import { DefaultState, RootContext, RootContextType, StateType } from './contexts/RootContext';
import { User } from './types/User';
import { Samples } from './pages/Samples';
import { Loading } from './pages/Loading';
import { Settings } from './pages/Settings';
import { Account } from './pages/Account';
import { AddEditSample, Mode } from './pages/AddEditSample';
import { PasswordChallenge } from './pages/PasswordChallenge';
import { SampleImages } from './pages/SampleImages';
import { ForgotPassword } from './pages/ForgotPassword';
import { API_BASE_URL } from './Constants';

const LOCAL_STORAGE_AUTH_KEY = 'auth';
const LOCAL_STORAGE_THEME_KEY = 'theme';

enum StateAction {
  LOGIN = "LOGIN",
  LOGOUT = "LOGOUT",
  INIT = "INIT",
  TOGGLE_DARK_MODE = "TOGGLE_DARK_MODE",
}

interface StateActionType {
  type: StateAction;
  payload: any | null;
}

interface StoredAuthDataType {
  user: User | null;
  idToken: string | null;
  accessToken: string | null;
  refreshToken: string | null;
}

interface StoredThemeDataType {
  darkMode: boolean | null;
}

export default function App() {
  const [loading, setLoading] = useState<boolean>(true);
  const pingHandle = useRef<number>(-1);
  const {setColorScheme} = useMantineColorScheme();
  const [state, dispatch] = useReducer(rootContextReducer, DefaultState);

  function startPingTimer(token: string) {
    cancelPingTimer();
    pingHandle.current = window.setInterval(() => ping(token), 30 * 1000);
  }

  function cancelPingTimer() {
    if (pingHandle.current !== -1) {
      window.clearInterval(pingHandle.current);
      pingHandle.current = -1;
    }
  }

  function rootContextReducer(state: StateType, action: StateActionType): StateType {
    const { type, payload } = action;
    switch (type) {
      case StateAction.LOGIN:
        localStorage.setItem(
          LOCAL_STORAGE_AUTH_KEY,
          JSON.stringify({
            user: payload.user,
            idToken: payload.idToken,
            accessToken: payload.accessToken,
            refreshToken: payload.refreshToken ? payload.refreshToken : ''
          })
        );
        startPingTimer(payload.idToken);
        return {
          ...state,
          authenticated: true,
          user: payload?.user,
          idToken: payload?.idToken,
          accessToken: payload?.accessToken,
          refreshToken: payload?.refreshToken,
        };
      case StateAction.LOGOUT:
        console.log('dispatching logout');
        localStorage.removeItem(LOCAL_STORAGE_AUTH_KEY);
        cancelPingTimer();
        return {
          ...state,
          idToken: null,
          accessToken: null,
          refreshToken: null,
          authenticated: false,
        };
      case StateAction.INIT:
        if (payload?.idToken) {
          startPingTimer(payload.idToken);
        }
        return {
          ...state,
          authenticated: payload?.user !== null && payload?.token !== null,
          user: payload?.user,
          idToken: payload?.idToken,
          accessToken: payload?.accessToken,
          refreshToken: payload?.refreshToken,
          darkMode: payload?.darkMode !== null ? payload?.darkMode : state.darkMode,
        };
      case StateAction.TOGGLE_DARK_MODE:
        const val = !state.darkMode;
        setColorScheme(val ? 'dark' : 'light');
        localStorage.setItem(LOCAL_STORAGE_THEME_KEY, JSON.stringify({darkMode: val}));
        return {
          ...state,
          darkMode: val,
        };
      default:
        return state;
    }
  }

  const rootContext = {
    state: state,
    login: (user: User, idToken: string, accessToken: string, refreshToken: string | null) => 
      dispatch({type: StateAction.LOGIN, payload: {user: user, idToken: idToken, accessToken: accessToken, refreshToken: refreshToken}}),
    logout: () => dispatch({type: StateAction.LOGOUT, payload: null}),
    init: (user: User | null, idToken: string | null, accessToken: string | null, refreshToken: string | null, darkMode: boolean | null) => 
      dispatch({type: StateAction.INIT, payload: {user: user, idToken: idToken, accessToken: accessToken, refreshToken: refreshToken, darkMode: darkMode}}),
    toggleDarkMode: () => dispatch({type: StateAction.TOGGLE_DARK_MODE, payload: null})
  } as RootContextType;

  async function ping(token: string): Promise<void> {
    console.log('pinging');
    const response = await fetch(`${API_BASE_URL}ping`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Authorization': token,
      },
    });
    console.log('ping status = ' + response.status);
    if (response.status === 401) {
      rootContext.logout();
    }
  }

  useEffect(() => {
    async function loadAuthData(): Promise<StoredAuthDataType> {
      const data = localStorage.getItem(LOCAL_STORAGE_AUTH_KEY);
      if (data) {
        try {
          const { user, idToken, accessToken, refreshToken } = JSON.parse(data);
          /*let check = idToken ? await appService.checkToken(idToken) : false;
          if (!check) {
            throw new Error('Invalid token');
          }*/
          return {user, idToken, accessToken, refreshToken};
        } catch (e) {
          localStorage.removeItem(LOCAL_STORAGE_AUTH_KEY);
        }
      }
      return {user: null, idToken: null, accessToken: null, refreshToken: null};
    }

    async function loadThemeData(): Promise<StoredThemeDataType> {
      const data = localStorage.getItem(LOCAL_STORAGE_THEME_KEY);
      if (data) {
        try {
          return JSON.parse(data);
        } catch (e) {
          localStorage.removeItem(LOCAL_STORAGE_THEME_KEY);
        }
      }
      return {darkMode: null};
    }
    
    (async function () {
      const { user, idToken, accessToken, refreshToken } = await loadAuthData();
      const { darkMode } = await loadThemeData();
      rootContext.init(user, idToken, accessToken, refreshToken, darkMode);
      if (idToken) {
        await ping(idToken);
      } else {
        rootContext.logout();
      }
      setTimeout(() => setLoading(false), 750);
    })();
  }, []);

  return (
    <RootContext.Provider value={rootContext}>
      <Routes>
        {loading ? (
          <>
            <Route path="*" element={<Loading />} />
          </>
        ) : (
          !state.authenticated ? (
            <>
              <Route path="/" element={<Login />} />
              <Route path="*" element={<Navigate to="/" />} />
              <Route path="/challenge" element={<PasswordChallenge />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
            </>
          ) : (
            <>
              <Route path="/" element={<Home />} />
              <Route path="*" element={<Navigate to="/" />} />
              <Route path="/samples" element={<Samples />} />
              <Route path="/add-sample" element={<AddEditSample mode={Mode.ADD} />} />
              <Route path="/edit-sample" element={<AddEditSample mode={Mode.EDIT} />} />
              <Route path="/sample-images" element={<SampleImages />} />
              <Route path="/settings" element={<Settings />} />
              <Route path="/account" element={<Account />} />
            </>
          )
        )}
      </Routes>
    </RootContext.Provider>
  );
}