import { Center, Tooltip, UnstyledButton, Stack, rem, AppShell, Title } from '@mantine/core';
import {
  IconHome2,
  IconUser,
  IconSettings,
  IconLogout,
  IconMountain,
  IconSun,
} from '@tabler/icons-react';
import classes from './PageShell.module.css';
import { useDisclosure } from '@mantine/hooks';
import { useNavigate } from 'react-router-dom';
import { RootContext } from '../contexts/RootContext';
import React from 'react';

interface NavbarLinkProps {
  icon: typeof IconHome2;
  label: string;
  active?: boolean;
  onClick?(): void;
}

function NavbarLink({ icon: Icon, label, active, onClick }: NavbarLinkProps) {
  return (
    <Tooltip label={label} position="right" transitionProps={{ duration: 0 }}>
      <UnstyledButton onClick={onClick} className={classes.link} data-active={active || undefined}>
        <Icon style={{ width: rem(20), height: rem(20) }} stroke={1.5} />
      </UnstyledButton>
    </Tooltip>
  );
}

const menuLinks = [
  { icon: IconHome2, label: 'Home', href: '/' },
  { icon: IconMountain, label: 'Samples', href: '/samples' },
  { icon: IconUser, label: 'Account', href: '/account' },
  { icon: IconSettings, label: 'Settings', href: '/settings' },
];

export interface PageShellProps {
  title?: string;
  activeNavLink?: string;
}

export function PageShell(props: React.PropsWithChildren<PageShellProps>) {
  const context = React.useContext(RootContext);
  const [opened, { toggle }] = useDisclosure();
  const navigate = useNavigate();

  const links = menuLinks.map((link, index) => (
    <NavbarLink
      key={link.label}
      label={link.label}
      icon={link.icon}
      active={props.activeNavLink === link.label}
      onClick={() => {
        navigate(link.href);
      }}
    />
  ));

  return (
    <AppShell
      navbar={{ width: 100, breakpoint: 'sm', collapsed: { mobile: !opened } }}
      padding="md"
    >
      <AppShell.Navbar className={classes.navbar}>
        <AppShell.Section grow className={classes.navbarMain}>
          <Stack justify="center" gap={0}>
            {links}
          </Stack>
        </AppShell.Section>
        <AppShell.Section>
          <Stack justify="center" gap={0}>
            <NavbarLink icon={IconSun} label="Toggle Dark Mode" onClick={() => context?.toggleDarkMode()}/>
            <NavbarLink icon={IconLogout} label="Logout" onClick={() => context?.logout()}/>
          </Stack>
        </AppShell.Section>
      </AppShell.Navbar>
      <AppShell.Main>
        <Center>
          <Title order={1} mb="lg">{props.title}</Title>
        </Center>
        {props.children}
      </AppShell.Main>
    </AppShell>
  );
}