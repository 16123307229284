import { Center, Container, Loader, Title, Notification, rem } from "@mantine/core";
import { PageShell } from "../components/PageShell";
import { useEffect, useState } from "react";
import { ImageUpload } from "../components/ImageUpload";
import { useSearchParams } from "react-router-dom";
import { useAppService } from "../services/AppService";
import { IconX } from "@tabler/icons-react";
import { LightType, Sample } from "../types/Types";
import { getLayerImagePath, getInterestAreaImagePath } from "../utils/SampleUtils";

const THREE_SIXTY_COUNT = 72;

enum PageStatus {
  LOADING,
  READY,
  ERROR,
}

export function SampleImages() {
  const appService = useAppService();
  const [searchParams, setSearchParams] = useSearchParams();
  const [sample, setSample] = useState<Sample>();
  const [status, setStatus] = useState<PageStatus>(PageStatus.LOADING);

  const onBaseDescriptionChange = async (sampleId: string, lightType: LightType, description: string) => {
    await appService.updateBaseScanDescription(sampleId, lightType, description);
  }

  const onInterestAreaDescriptionChange = async (sampleId: string, interestAreaId: string, lightType: LightType, description: string) => {
    await appService.updateInterestAreaScanDescription(sampleId, interestAreaId, lightType, description);
  }
  
  useEffect(() => {
    (async function () {
      try {
        const id = searchParams.get('id');
        if (id === null) {
          setStatus(PageStatus.ERROR);
          return;
        }
        const sample = await appService.getSample(id);
        if (sample === null) {
          setStatus(PageStatus.ERROR);
          return;
        }
        setSample(sample);
        setStatus(PageStatus.READY);
        if (sample === null) {
          setStatus(PageStatus.ERROR);
          return;
        }
      } catch (e) {
        setStatus(PageStatus.ERROR);
      }
    })();
  }, []);

  if (status === PageStatus.LOADING) {
    return (
      <PageShell title="Sample Images" activeNavLink="Samples">
        <Container size="lg" mt={5} mb={30}>
          <Center>
            <Loader mt={50} size="md" variant="bars" />
          </Center>
        </Container>
      </PageShell>
    )
  } else if (status === PageStatus.ERROR) {
    return (
      <PageShell title="Sample Images" activeNavLink="Samples">
        <Container size="lg" mt={5} mb={30}>
          <Center>
            <Notification withCloseButton={false} withBorder icon={<IconX style={{ width: rem(20), height: rem(20) }} />} color="red" title="Error" mt="md">
              A problem was encoutered while loading sample data. Please try again later.
            </Notification>
          </Center>
        </Container>
      </PageShell>
    )
  }
  return (
    <PageShell title={'Sample Images: ' + sample?.name} activeNavLink="Samples">
      <Container size="lg" mt={5} mb={30}>
        <Title order={3} ml={5} mb={10}>Layer Images</Title>
        {sample?.scans.map(sc => {
          const imagePath = getLayerImagePath(sample.id, sc.lightType);
          return (
            <ImageUpload
              title={LightType[sc.lightType] + ' Layer Image'}
              uploadFilePath={imagePath + '/original-layer.jpg'}
              displayFilePath={imagePath + '/layer.jpg?c=' + new Date().getTime()}
              description={sc.description}
              onDescriptionChange={(d: string) => onBaseDescriptionChange(sample.id, sc.lightType, d)}
            />
          )
        })}
        <Title order={3} ml={5} mb={10}>Other Images</Title>
        <ImageUpload
          title='Preview Image'
          uploadFilePath={sample?.id + '/original-sample.jpg'}
          displayFilePath={sample?.id + '/sample_medium.jpg?c=' + new Date().getTime()}
        />
        <Title order={3} ml={5} mt={30} mb={10}>Interest Area Images</Title>
        {sample?.interestAreas?.map((ia, i) => (
          <>
            <Title order={4} ml={5} mb={10}>Area {i + 1}: {ia.threeSixty && '360°'} ({ia.position.x},{ia.position.y})</Title>
            {ia.scans.map(iasc => {
              const imagePath = getInterestAreaImagePath(sample.id, ia.id, iasc.lightType);
              return (
                <ImageUpload
                  title={LightType[iasc.lightType] + ' Interest Area Image'}
                  uploadFilePath={imagePath + '/original-interest.jpg'}
                  displayFilePath={imagePath + '/static.jpg?c=' + new Date().getTime()}
                  description={iasc.description}
                  onDescriptionChange={(d: string) => onInterestAreaDescriptionChange(sample.id, ia.id, iasc.lightType, d)}
                  count={ia.threeSixty ? THREE_SIXTY_COUNT : 1}
                />
              )
            })}
          </>
        ))}
      </Container>
    </PageShell>
  );
}