import React from "react";
import { User } from "../types/User";

export interface StateType {
  authenticated: boolean;
  user: User | null;
  idToken: string | null;
  accessToken: string | null;
  refreshToken: string | null;
  darkMode: boolean;
}

export const DefaultState = {
  authenticated: false,
  user: null,
  idToken: null,
  accessToken: null,
  refreshToken: null,
  darkMode: true,
} as StateType;

export interface RootContextType {
  state: StateType;
  login(user: User, idToken: string, accessToken: string, refreshToken: string | null): void;
  logout(): void;
  init(user: User | null, idToken: string | null, accessToken: string | null, refreshToken: string | null, darkMode: boolean | null): void;
  toggleDarkMode(): void;
}

export const RootContext = React.createContext<RootContextType | null>(null);