import { ActionIcon, Button, Fieldset, Group, Modal, Textarea, Tooltip, useMantineTheme } from '@mantine/core';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useAppService } from '../services/AppService';
import { IMAGE_BASE_URL } from '../Constants';
import { IconAlertTriangle, IconArrowRight, IconCheck, IconTextPlus } from '@tabler/icons-react';
import { useDisclosure } from '@mantine/hooks';
import { isStringEmpty } from '../utils/ValidationUtils';
import { useForm } from '@mantine/form';

const DEFAULT_COUNT = 1;
const FORM_SPACING = 30;

enum UploadStatus {
  READY,
  IN_PROGRESS,
  DONE,
  ERROR,
}

enum FormStatus {
  READY,
  SUBMITTING,
  ERROR,
  SUCCESS,
}

interface FormValues {
  description: string;
}

export enum ImageType {
  LAYER,
  PREVIEW,
  INTEREST_AREA,
}

export interface ImageUploadProps {
  title: string;
  uploadFilePath: string;
  displayFilePath: string;
  description?: string;
  count?: number,
  onDescriptionChange?(description: string): Promise<void>;
}

export function ImageUpload(props: ImageUploadProps) {
  const { title, uploadFilePath, displayFilePath } = props;
  const count = props.count || DEFAULT_COUNT;
  const appService = useAppService();
  const [uploadStatus, setUploadStatus] = useState<UploadStatus>(UploadStatus.READY);
  const [formStatus, setFormStatus] = useState<FormStatus>(FormStatus.READY);
  const [message, setMessage] = useState<string>('');
  const files = useRef<File[]>([]);
  const [index, setIndex] = useState<number>(0);
  const [opened, { open, close }] = useDisclosure(false);
  const theme = useMantineTheme();
  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: {'image/jpeg': []},
    maxFiles: count,
    onDrop: async acceptedFiles => {
      if (acceptedFiles.length !== count) {
        setMessage('A valid number of files must be selected for the upload to begin.');
        setTimeout(() => setMessage(''), 4000);
        return;
      }
      files.current = acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      }));;
      setTimeout(uploadFiles, 100);
    }
  });
  const form = useForm<FormValues>({
    validateInputOnBlur: true,
    initialValues: {
      description: props.description ? props.description : '',
    },
    validate: (values) => {
      let errors: {[key: string]: string} = {};
      if (isStringEmpty(values.description)) {
        errors['description'] = 'Description is required';
      }
      return (errors);
    },
  });

  const uploadNextFile = async (idx: number = 0) => {
    console.log('uploading next, index = ' + index + ', idx = ' + idx + ', flength = ' + files.current.length);
    if (idx < files.current.length) {
      try {
        setIndex(idx + 1);
        await appService.uploadImage(uploadFilePath, files.current[idx]);
        setTimeout(() => uploadNextFile(idx + 1), 2000);
      } catch (e) {
        setUploadStatus(UploadStatus.ERROR);
      }
    } else {
      setIndex(0);
      setUploadStatus(UploadStatus.DONE);
      setTimeout(() => setUploadStatus(UploadStatus.READY), 4000);
    }
  }

  const uploadFiles = () => {
    setUploadStatus(UploadStatus.IN_PROGRESS);
    uploadNextFile();
  }

  const getBackgroundImage = (): string => {
    if (files.current.length > 0) {
      return (files.current[0] as any).preview;
    }
    return `${IMAGE_BASE_URL}${displayFilePath}`;
  }

  const style = useMemo(() => {
    const baseStyle = {
      display: 'flex',
      borderWidth: 2,
      borderRadius: 2,
      borderColor: theme.colors.gray[7],
      borderStyle: 'dashed',
      outline: 'none',
      transition: 'border .24s ease-in-out',
      width: '100%',
      height: 100,
      backgroundImage: `url(${getBackgroundImage()})`,
      backgroundSize: 'cover',
    };
    
    const focusedStyle = {
      borderColor: theme.primaryColor
    };
    
    const acceptStyle = {
      borderColor: '#00e676'
    };
    
    const rejectStyle = {
      borderColor: '#ff1744'
    };

    const errorStyle = {
      color: '#ff0000',
    }

    return ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
      ...(uploadStatus === UploadStatus.ERROR ? errorStyle : {})
    });
  }, [files, isDragAccept, isDragReject, isFocused, theme.colors.gray, theme.primaryColor, uploadStatus]);

  const handleSubmit = async (values: typeof form.values) => {
    if (formStatus !== FormStatus.READY) {
      return;
    }
    setFormStatus(FormStatus.SUBMITTING);
    try{
      console.log('values = ' + JSON.stringify(values));
      if (props.onDescriptionChange) {
        await props.onDescriptionChange(values.description);
      }
      setFormStatus(FormStatus.SUCCESS);
      setTimeout(() => {
        form.resetDirty();
        setFormStatus(FormStatus.READY);
      }, 2000);
    } catch (e) {
      setFormStatus(FormStatus.ERROR);
      setTimeout(() => {
        setFormStatus(FormStatus.READY);
      }, 2000);
    }
  };

  const handleErrors = (errors: typeof form.errors) => {
    console.log('errors = ' + JSON.stringify(errors));
  }

  const getButtonContent = (): JSX.Element => {
    if (formStatus === FormStatus.SUBMITTING) {
      return <>Submitting...</>;
    } else if (formStatus === FormStatus.SUCCESS) {
      return <IconCheck />
    } else if (formStatus === FormStatus.ERROR) {
      return <IconAlertTriangle />
    } else {
      return <>
        Submit
      </>
    }
  }

  useEffect(() => {
    return () => files.current.forEach(file => URL.revokeObjectURL((file as any).preview));
  }, []);

  const statusText = (function () {
    if (message !== '') {
      return message;
    }
    switch (uploadStatus) {
      case UploadStatus.READY:
        return "Drag 'n' drop file here, or click to select a file";
      case UploadStatus.IN_PROGRESS:
        return `Uploading file(s)... (${index}/${files.current.length})`;
      case UploadStatus.DONE:
        return 'All files have been uploaded successfully!';
      case UploadStatus.ERROR:
        return 'A problem was encountered while uploading the file(s).';
    }
  })();
    
  return (
    <Fieldset legend={title} mb={20} p={20} style={{position: 'relative'}}>
      <div {...getRootProps({style})}>
        <input {...getInputProps()} />
        <div style={{display: 'flex', width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center',}}>
          <div style={{fontWeight: 'bold', borderRadius: 5, padding: 5, backgroundColor: 'rgba(0, 0, 0, 0.7'}}>
            {statusText}
          </div>
        </div>
      </div>
      {'description' in props && (
        <>
          <Tooltip label="Set Description" color="gray">
            <ActionIcon onClick={open} style={{position: 'absolute', right: -40, top: -10}} variant="filled" aria-label="Edit">
              <IconTextPlus style={{ width: '70%', height: '70%' }} stroke={1.5} />
            </ActionIcon>
          </Tooltip>
          <Modal opened={opened} onClose={close} title="Image Data">
            <form onSubmit={form.onSubmit(handleSubmit, handleErrors)}>
              <Group grow mb={FORM_SPACING}  align="flex-start">
                <Textarea
                  label="Description"
                  withAsterisk
                  description="Image description"
                  autosize
                  minRows={10}
                  {...form.getInputProps('description')}
                />
              </Group>
              <Group mt={FORM_SPACING}>
                <Button type="submit" disabled={!form.isDirty()} loading={formStatus === FormStatus.SUBMITTING} fullWidth>
                  {getButtonContent()}
                </Button>
              </Group>
            </form>
          </Modal>
        </>
      )}
    </Fieldset>
  );
}