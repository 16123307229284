export interface Position {
  x: number;
  y: number;
}

export interface Dimension {
  width: number;
  height: number;
}

export interface Coordinates {
  latitude: number;
  longitude: number;
}

export interface Reference {
  title: string;
  link?: string;
}

export interface InterestArea {
  id: string;
  position: Position;
  radius: number;
  title: string;
  description: string;
  scans: Scan[];
  threeSixty: boolean;
}

export interface Scan {
  lightType: LightType;
  description?: string;
}

export interface Sample {
  id: string;
  name: string;
  defaultDimension: Dimension;
  maxZoom: number;
  relatedSamples: string[];
  coordinates: Coordinates;
  references: Reference[];
  scans: Scan[];
  oreColor: string;
  interestAreas?: InterestArea[];
  country: string;
  state: string;
  oreDepositType: OreDepositType;
  factSheet: string;
  geology: string;
  mineralogy: string;
  age: string;
  active: boolean;
}

export enum LightType {
  RL,
  XPL,
  PPL,
  XRL,
}

export const LightTypes: LightType[] = [
  LightType.RL,
  LightType.XPL,
  LightType.PPL,
  LightType.XRL,
]

export enum OreDepositType {
  PORPHYRY_COPPER_DEPOSITS,
  VOLCANOGENIC_MASSIVE_SULFIDE,
  MISSISSIPPI_VALLEY_TYPE_DEPOSITS,
  SEDIMENTARY_EXHALATIVE_DEPOSITS,
  RARE_EARTH_ELEMENTS_DEPOSITS,
  FE_TI_DEPOSITS,
  AG_DEPOSITS,
  URANIUM_DEPOSITS,
  MISCELLANEOUS,
}

export const OreDepositTypes: OreDepositType[] = [
  OreDepositType.PORPHYRY_COPPER_DEPOSITS,
  OreDepositType.VOLCANOGENIC_MASSIVE_SULFIDE,
  OreDepositType.MISSISSIPPI_VALLEY_TYPE_DEPOSITS,
  OreDepositType.SEDIMENTARY_EXHALATIVE_DEPOSITS,
  OreDepositType.RARE_EARTH_ELEMENTS_DEPOSITS,
  OreDepositType.FE_TI_DEPOSITS,
  OreDepositType.AG_DEPOSITS,
  OreDepositType.URANIUM_DEPOSITS,
  OreDepositType.MISCELLANEOUS,
];
