import { useState, useEffect, useContext, useRef } from 'react';
import {
  Container,
  Table,
  Center,
  Loader,
  ActionIcon,
  Flex,
  Group,
  SimpleGrid,
  TextInput,
  Tooltip,
  Anchor
} from '@mantine/core';
import { PageShell } from "../components/PageShell";
import { IconBoxMultiple, IconEdit, IconPhoto, IconPlus, IconRefresh, IconX } from '@tabler/icons-react';
import { useAppService } from '../services/AppService';
import { RootContext } from '../contexts/RootContext';
import { LightType, Sample } from '../types/Types';
import { Link } from 'react-router-dom';

interface SamplesState {
  scrolled: boolean;
  loading: boolean;
  sampleDatas: Sample[];
  search?: string;
}

const DEFAULT_SAMPLES_STATE: SamplesState = {
  scrolled: false,
  loading: false,
  sampleDatas: [],
  search: '',
};

export function Samples() {
  const context = useContext(RootContext);
  const [state, setState] = useState<SamplesState>(DEFAULT_SAMPLES_STATE);
  const [canRefresh, setCanRefresh] = useState<boolean>(true);
  const appService = useAppService();
  const searchRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    (async function() {
      await fetchData();
    })();
  }, []);

  async function fetchData() {
    setState({...state, loading: true});
    try {
      const sampleDatas = await appService.getSamples();
      if (sampleDatas === null) {
        console.warn('Could not get samples');
        return;
      }
      setState({
        ...state,
        loading: false,
        sampleDatas: sampleDatas,
      });
      setCanRefresh(false);
      setTimeout(() => setCanRefresh(true), 5000);
    } catch (e) {
      console.error(e);
    } 
  }

  function getLightTypes(sample: Sample) {
    let result = [];
    const length = sample.scans.length;
    if (length === 0) {
      result.push("N/A");
    } else {
      for (let i = 0; i < length; i++) {
        result.push(LightType[sample.scans[i].lightType] + (i < length - 1 ? ", " : ""));
      }
    }
    return result;
  }

  async function onDeleteClick(id: string) {
    if (window.confirm('Warning: This sample is about to be permanently deleted and cannot be recovered. Are you absolutely sure you want to continue?')) {
      await appService.deleteSample(id);
      setState({
        ...state,
        sampleDatas: state.sampleDatas.filter(s => s.id !== id),
      })
    }
  }

  function onSearchChange(e: any) {
    const search = e.currentTarget.value;
    setState({
      ...state,
      search: search,
    });
  }

  function clearSearch() {
    setState({
      ...state,
      search: '',
    });
  }

  function filterSearch(sample: Sample) {
    return !state.search || sample.name.toLocaleLowerCase().indexOf(state.search.toLocaleLowerCase()) !== -1;
  }

  function getRows() {
    let sampleDatas = state.sampleDatas.filter(filterSearch);
    if (sampleDatas.length > 0) {
      const rows = sampleDatas.map((s) => (
        <Table.Tr key={s.id}>
          <Table.Td><Center><Anchor href={'/edit-sample?id=' + s.id}>{s.id.slice(0, 20)}...</Anchor></Center></Table.Td>
          <Table.Td>{s.name}</Table.Td>
          <Table.Td>{s.country}</Table.Td>
          <Table.Td>{s.age}</Table.Td>
          <Table.Td>{s.defaultDimension.width + 'x' + s.defaultDimension.height}</Table.Td>
          <Table.Td>{getLightTypes(s)}</Table.Td>
          <Table.Td><Center>{s.active ? 'Yes' : 'No'}</Center></Table.Td>
          <Table.Td width={200}>
            <Center>
              <Group>
                <Link to={'/edit-sample?id=' + s.id}>
                  <Tooltip label="Edit" color="gray">
                    <ActionIcon variant="filled" aria-label="Edit">
                      <IconEdit style={{ width: '70%', height: '70%' }} stroke={1.5} />
                    </ActionIcon>
                  </Tooltip>
                </Link>
                <Link to={'/sample-images?id=' + s.id}>
                  <Tooltip label="Images" color="gray">
                    <ActionIcon variant="filled" aria-label="Images">
                      <IconPhoto style={{ width: '70%', height: '70%' }} stroke={1.5} />
                    </ActionIcon>
                  </Tooltip>
                </Link>
                <Link to={'/add-sample?id=' + s.id}>
                  <Tooltip label="Duplicate" color="gray">
                    <ActionIcon variant="filled" aria-label="Images">
                      <IconBoxMultiple style={{ width: '70%', height: '70%' }} stroke={1.5} />
                    </ActionIcon>
                  </Tooltip>
                </Link>
                <Tooltip label="Delete" color="gray">
                  <ActionIcon variant="filled" aria-label="Delete" onClick={() => onDeleteClick(s.id)}>
                    <IconX style={{ width: '70%', height: '70%' }} stroke={1.5} />
                  </ActionIcon>
                </Tooltip>
              </Group>
            </Center>
          </Table.Td>
        </Table.Tr>
      ));
      return rows;
    } else {
      return (
        <Table.Tr>
          <Table.Td colSpan={8}><Center>No samples found. Have you tried refreshing or adding a new sample?</Center></Table.Td>
        </Table.Tr>
      );
    }
  }

  return (
    <PageShell title="Samples" activeNavLink="Samples">
      <Container size="xl" mt={15}>
        <SimpleGrid cols={2} mb={10}>
          <Group>
            <TextInput placeholder='Enter search' value={state.search} onChange={onSearchChange} rightSection={state.search !== '' && <ActionIcon variant="subtle"><IconX onClick={clearSearch} /></ActionIcon>} />
            <Tooltip label="Refresh" color="gray">
              <ActionIcon onClick={fetchData} size="lg" variant="filled" disabled={state.loading || !canRefresh}>
                <IconRefresh size="1.625rem" />
              </ActionIcon>
            </Tooltip>
          </Group>
          <Flex justify={'flex-end'} align={'flex-end'} direction={'row'}>
            <Link to={'/add-sample'}>
              <ActionIcon size="lg" variant="filled">
                <IconPlus size="1.625rem" />
              </ActionIcon>
            </Link>
          </Flex>
        </SimpleGrid>
        <Table striped highlightOnHover withTableBorder stickyHeader stickyHeaderOffset={60} miw={700} horizontalSpacing="md" verticalSpacing="md">
          <Table.Thead>
            <Table.Tr>
              <Table.Th><Center>ID</Center></Table.Th>
              <Table.Th>Name</Table.Th>
              <Table.Th>Country</Table.Th>
              <Table.Th>Age</Table.Th>
              <Table.Th>Dimension</Table.Th>
              <Table.Th>Light Types</Table.Th>
              <Table.Th><Center>Active</Center></Table.Th>
              <Table.Th><Center>Action</Center></Table.Th>
            </Table.Tr>
          </Table.Thead>
          {state.loading ? (
            <Table.Tbody>
              <Table.Tr>
                <Table.Td colSpan={8}>
                <Center>
                  <Loader size="md" variant="bars" />
                </Center>
                </Table.Td>
              </Table.Tr>
            </Table.Tbody>
          ) : (
            <Table.Tbody>{getRows()}</Table.Tbody>
          )}
        </Table>
      </Container>
    </PageShell>
  );
}
