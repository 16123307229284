import { useState, useContext } from 'react';
import {
  TextInput,
  Paper,
  Title,
  Text,
  Container,
  Button,
  Notification,
  Anchor,
  rem,
  PasswordInput,
} from '@mantine/core';
import { useForm, isNotEmpty, hasLength } from '@mantine/form';
import { useAppService } from '../services/AppService';
import './Login.css';
import { RootContext } from '../contexts/RootContext';
import { IconCheck, IconLogin, IconX } from '@tabler/icons-react';

enum FormStep {
  FORGOT,
  CODE,
}

enum FormStatus {
  READY,
  SUBMITTING,
  SUCCESS,
  ERROR,
}

interface ForgotFormValues {
  username: string;
}

interface ConfirmFormValues {
  code: string;
  password: string;
  confirmPassword: string;
}

export function ForgotPassword() {
  const context = useContext(RootContext);
  const appService = useAppService();
  const [formStep, setFormStep] = useState<FormStep>(FormStep.FORGOT);
  const [forgotFormStatus, setForgotFormStatus] = useState<FormStatus>(FormStatus.READY);
  const [codeFormStatus, setCodeFormStatus] = useState<FormStatus>(FormStatus.READY);
  const forgotForm = useForm<ForgotFormValues>({
    validateInputOnBlur: true,
    initialValues: {
      username: '',
    },
    validate: {
      username: isNotEmpty('Username is required'),
    },
  });
  const codeForm = useForm<ConfirmFormValues>({
    validateInputOnBlur: true,
    initialValues: {
      code: '',
      password: '',
      confirmPassword: '',
    },
    validate: {
      code: isNotEmpty('Code is required'),
      password: hasLength({ min: 6 }, 'Password should contain at least 6 characters'),
      confirmPassword: (value, values) => {
        console.log('value = ' + value + ', values.password = ' + values.password);
        return value !== values.password ? 'Passwords do not match' : null;
      }
    },
  });

  async function onForgotSubmit(values: any) {
    setForgotFormStatus(FormStatus.SUBMITTING);
    try {
      await appService.forgotPassword(values.username);
      setForgotFormStatus(FormStatus.SUCCESS);
      setFormStep(FormStep.CODE);
    } catch (e: any) {
      console.error(e);
      setForgotFormStatus(FormStatus.ERROR);
    }
  };

  async function onCodeSubmit(values: any) {
    setCodeFormStatus(FormStatus.SUBMITTING);
    try {
      await appService.confirmForgotPassword(values.code, forgotForm.values.username, values.password);
      setCodeFormStatus(FormStatus.SUCCESS);
      //setFormStep(FormStep.FORGOT);
      codeForm.reset();
    } catch (e: any) {
      console.error(e);
      setCodeFormStatus(FormStatus.ERROR);
      setForgotFormStatus(FormStatus.READY);
    }
  };

  const xIcon = <IconX style={{ width: rem(20), height: rem(20) }} />;
  const checkIcon = <IconCheck style={{ width: rem(20), height: rem(20) }} />;
  
  return (
    <Container size={620} my={40}>
      <Title ta="center">
        Forgot Password
      </Title>
      <Text c="dimmed" size="sm" ta="center" mt={5}>
        Please complete the form below to receive a verification code.
      </Text>
      <Paper withBorder shadow="md" p={30} mt={30} radius="md">
        {(forgotFormStatus === FormStatus.ERROR || codeFormStatus === FormStatus.ERROR) ? (
          <Notification withBorder mb={30} icon={xIcon} color="red" title="Error">
            {codeFormStatus === FormStatus.ERROR ? (
              <>Your password could not be reset. Please try again later.</>
            ) : (
              <>Your verification code could not be sent. Please try again later.</>
            )}
          </Notification>
        ) : (
          <>
            {(forgotFormStatus === FormStatus.SUCCESS || codeFormStatus === FormStatus.SUCCESS) && (
              <Notification withBorder mb={30} icon={checkIcon} color="teal" title="Success">
                {codeFormStatus === FormStatus.SUCCESS ? (
                  <>Your password has been successfully reset. You may <Anchor href="/" size="sm">login</Anchor> now.</>
                ) : (
                  <>Your verification code was successfully sent.</>
                )}
              </Notification>
            )}
          </>
        )}
        {formStep === FormStep.FORGOT ? (
          <form onSubmit={forgotForm.onSubmit((values) => onForgotSubmit(values))}>
            <TextInput
              withAsterisk
              label="Username"
              placeholder="Enter username"
              {...forgotForm.getInputProps('username')}
            />
            <Button type="submit" leftSection={<IconLogin size="1rem" />} loading={forgotFormStatus === FormStatus.SUBMITTING} fullWidth mt="xl">
              {forgotFormStatus === FormStatus.SUBMITTING ? 'Submitting...' : 'Submit'}
            </Button>
          </form>
        ) : (
          <form onSubmit={codeForm.onSubmit((values) => onCodeSubmit(values))}>
            <TextInput
              withAsterisk
              label="Code"
              placeholder="Enter code"
              {...codeForm.getInputProps('code')}
            />
            <PasswordInput
              withAsterisk
              label="New Password"
              placeholder="Enter your password"
              mt="md"
              {...codeForm.getInputProps('password')}
            />
            <PasswordInput
              withAsterisk
              label="Confirm Password"
              placeholder="Enter your password"
              mt="md"
              {...codeForm.getInputProps('confirmPassword')}
            />
            <Button type="submit" leftSection={<IconLogin size="1rem" />} loading={codeFormStatus === FormStatus.SUBMITTING} fullWidth mt="xl">
              {codeFormStatus === FormStatus.SUBMITTING ? 'Submitting...' : 'Submit'}
            </Button>
          </form>
        )}
      </Paper>
    </Container>
  );
}